<template>
    <authed-page>
        <div v-if="!ready">
            <div class="loading-block">
                <a-spin/>
            </div>
        </div>
        <div v-else-if="needPay">
            <reg-pay @finished="payFinished"/>
        </div>
        <div v-else class="ast-reg">
            <div class="ast-reg-left">
                <div class="ast-reg-left-bg"></div>
            </div>
            <div class="ast-reg-right">

                <basic v-if=" type === 'basic' " :company-info="companyInfo" @success="typeChanged"/>

                <info v-if="type === 'info' " :company-info="companyInfo" @success="typeChanged"/>

                <auth v-if="type === 'auth' " :app="app" :company-info="companyInfo" @success="typeChanged" @companyChanged="init()"/>

                <ban v-if="type === 'ban' " :app="app" :company-info="companyInfo" @success="typeChanged"/>

                <pay v-if="type === 'pay' " :app="app" :company-info="companyInfo" @success="typeChanged"></pay>

                <preview v-if="type === 'preview' " :app="app" :apply-id=applyId :type="regType" @change="switchType"/>

            </div>
        </div>
    </authed-page>
</template>

<script>
import AuthedPage from "../../../layout/AuthedPage";
import Basic from "./Com/Basic";
import api from "../../../repo/api";
import Preview from "./Com/Preview";
import Info from './Com/Info';
import Auth from "@/views/AST/Reg/Com/Auth.vue";
import Ban from './Com/Ban';
import auth from "@/auth";
import Pay from "@/views/AST/Reg/Com/Pay.vue";
import RegPay from "@/views/AST/Reg/RegPay.vue";

export default {
    name: "Index",
    components: {RegPay, Pay, Preview, Info, Auth, Basic, AuthedPage, Ban},
    data() {
        return {
            type: '',
            // debugType: 'ban',
            regType: 'none',
            applyId: 0,
            companyInfo: undefined,
            app: null,
            needPay: false,
            ready: false,
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        finish(applyId) {
            this.regType = this.type
            this.applyId = applyId
            this.type = 'finish'
        },
        payFinished() {
            this.ready = false
            this.needPay = false
            this.checkPt()
        },
        checkPt() {

            api.get('/ast-reg/pt', (data) => {

                this.ready = true

                if (!data.data) {

                    return this.needPay = true
                }

                this.type = 'basic'
            })

        },
        init() {

            this.refreshCompanyInfo().then((info) => {



                if (!info || !info.app_id) {

                    this.checkPt();

                    return;
                }

                this.ready = true
                this.type = 'info'

                let app = info.ast_app

                auth.appId(app.id)

                this.app = app;

                if (this.debugType) {
                    this.type = this.debugType
                    return;
                }
                
                if ((!app.signature) || (!app.avatar)) return;
                
                this.type = 'auth';

                let appInfo = app.info;

                if (appInfo.mp_verify < 1) return;

                this.type = 'ban';

                if (!info.has_ban) return;

                this.type = 'pay';

                if (!info.has_pay_apply) return;

                this.type = 'preview'
            })
        },
        switchType(type) {
            this.type = type
        },
        typeChanged() {
            this.init()
        },
        refreshCompanyInfo() {
            return new Promise((resolve) => {

                this.$loading.show()

                api.get('/ast-reg/company-info', (data) => {

                    this.$loading.hide()

                    this.companyInfo = data.data

                    if (this.companyInfo instanceof Array) {
                        this.companyInfo = {}
                    }

                    resolve(this.companyInfo)
                })

            });
        }
    }
}
</script>

<style lang="less" scoped>
.ast-reg {
    display: flex;
    min-height: 100vh;

    .ast-reg-left {
        width: 40%;
    }

    .ast-reg-right {
        flex: 1;
        background-color: #fff;
        position: relative;
        z-index: 2;

        padding: 80px 120px;
    }
}

.ast-reg-left-bg {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 40%;
    background-image: url("/assets/images/ast-reg-left.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

</style>